import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Logo from "./images/Logo.png";
import { useHistory } from 'react-router-dom';

import Success from "./images/success2.gif";

import wrong from "./images/wrong1.gif";

import warning from "./images/warning.gif";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    borderRadius: 10,
    // width: 500,
    // height:280,
    width: "30em",
    height: "30em",
    // backgroundColor:"#E8FFFD",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 10,
  },
  pos: {
    marginBottom: 12,
  },

  back: {
    //green
    // background: "linear-gradient(to right, #2AC4A3 40%, #16A5A9 60%)",
    //blue
    background:
      "linear-gradient(108.26585207970061deg, rgba(1, 96, 186,1) 5.894948755490484%,rgba(0, 0, 0,1) 95.28001464128842%)",
    //yellow
    // background: "linear-gradient(315deg, #fbb034 0%, #ffdd00 74%)",
    //  background:"linear-gradient(to right, rgba(22,163,170,0.5), rgba(22,163,170,1))",
    height: "100vh",
    // height:"",
    // width:"100%",
  },
});

export default function OutlinedCard() {
  const classes = useStyles();
  const history = useHistory();
  const bull = <span className={classes.bullet}>•</span>;
  const [image,setImage] = useState(wrong)
  const [message,setMessage] = useState('Your Registration was not completed!')
  useEffect(() => {
    if (localStorage.getItem('is_success')==='true') {
      setImage(Success)
      setMessage('Your Registration was completed.!')
    }
    localStorage.removeItem('data')
    localStorage.removeItem('country')
    localStorage.removeItem('total_price')
  }, []);
  if(!localStorage.getItem('is_success'))
  {
    history.push("/");
  }
  return (
    <Grid
      container
      //  direction="row"
      className={classes.back}
      alignItems="center"
      justify="center"
    >
      <Grid container justify="center" alignItems="center">
        <Card
          className={classes.root}
          //  style={{position:"relative"}}
          variant="outlined"
          style={{overflowY: "auto",
          height: "30em",
          overflowX: "hidden",}}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: "15em",
                  // alignItems: "flex-start",
                  backgroundColor: "#244F50",
                  borderRadius: 10,
                  // position:"absolute",
                  // top:170,
                  // right:300,
                  // left:10,
                  overflow: "auto",
                  margin: "0.5em",
                }}
              ></img>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <CardContent
              style={{
                // backgroundColor:"#E8FFFD",
                color: "#013F7A",
                marginTop: "0.4em",
              }}
            >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <img  style={{width:"10em" }} src={image} />
                  {/* <img  style={{width:"10em" }} src={wrong} /> */}
                  {/* <img style={{ width: "10em" }} src={warning} /> */}
                </Grid>

                {/* <Grid item >  */}
                <Grid
                  container
                  direction="column"
                  justify="center "
                  alignItems="center"
                  style={{ backgroundColor: "#FAFAFA", borderRadius: 10 }}
                >
                  {/* <Typography style={{fontWeight:"bold"}} variant="h5"> Thank You!</Typography>   
            <Typography  variant="h6"> Your Payment was completed</Typography> */}

                  {/* <Typography style={{fontWeight:"bold"}} variant="h5"> Please try Again</Typography>   
            <Typography  variant="h6"> Your Payment was failed</Typography> */}

                  <Typography style={{ fontWeight: "bold" }} variant="h5">
                    {" "}
                    {message}
                  </Typography>
                  <Typography variant="h6">
                    {" "}
                    The email with the portal link and access information has been sent to your email.!
                  </Typography>
                </Grid>

                {/* <Grid item >
   
           </Grid>  */}

                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Grid>
          <CardActions>
            <Grid container justify="center" style={{ marginTop: "0em" }}>
              <Button
                href='https://start.campuskloud.io'
                style={{
                  backgroundColor: "#013F7A",
                  color: "White",
                  width: "9em",
                  marginRight: 10,
                }}
              >
                OK
              </Button>
              
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
